import { GetServerSidePropsContext } from 'next'
import { withSessionSsr } from 'lib/session'

// @shared
import PublicLayout from '@shared/components/layout/PublicLayout'

// public pages
import AboutUsPage, { type AboutUsPageProps } from '@src/pages/public/AboutUs/AboutUsPage'

const LEADERSHIP_TEAM = {
  title: 'Leadership Team',
  description:
    'We’re a small team of passionate founders and creators, driven to reshape the art world with technology and innovation.',
  persons: [
    {
      avatar: '/images/landing-pages/about/leadership-team/kendall.png',
      name: 'Kendall Warson',
    },
    {
      avatar: '/images/landing-pages/about/leadership-team/shyevin.png',
      name: "Shyevin S'ng",
    },
    {
      avatar: '/images/landing-pages/about/leadership-team/sam.png',
      name: 'Sam Tran',
    },
    {
      avatar: '/images/landing-pages/about/leadership-team/lauren.png',
      name: 'Lauren Kulwicki',
    },
  ],
}

const OUR_STRATEGIC_ADVISORS = {
  title: 'Our Strategic Advisors',
  persons: [
    {
      avatar: '/images/landing-pages/about/advisors/paul.png',
      name: 'Paul D. Henderson',
      jobTitle: 'Legal/Cultural Advocate',
    },
    {
      avatar: '/images/landing-pages/about/advisors/tom.png',
      name: 'Tom Tandio',
      jobTitle: 'Art Jakarta Founder',
    },
    {
      avatar: '/images/landing-pages/about/advisors/carrie.png',
      name: 'Carrie Schwab',
      jobTitle: 'Corporate Director',
    },
    {
      avatar: '/images/landing-pages/about/advisors/daisuke.png',
      name: 'Daisuke Miyatsu',
      jobTitle: 'Avant-Garde Collector',
    },
    {
      avatar: '/images/landing-pages/about/advisors/susan.png',
      name: 'Susan Washington',
      jobTitle: 'Artist Ambassador',
    },
    {
      avatar: '/images/landing-pages/about/advisors/paris.png',
      name: 'Paris Hilton',
      jobTitle: 'Pop Culture Icon',
    },
  ],
}

const JOIN_US = {
  title: 'Join Us on Our Journey',
  description:
    "Whether you're an artist or advisor, join the community to create a more connected and accessible art world.",
}

const BACKED_BY = {
  title: `We’re Backed By`,
  images: [
    {
      name: 'AngelList',
      src: '/images/landing-pages/about/backed-by/angellist.png',
      className: 'h-auto w-[150px]',
      mobileClassName: 'h-full w-[150px] !object-contain',
    },
    {
      name: '11:11 Media',
      src: '/images/landing-pages/about/backed-by/media.png',
      className: 'h-auto w-[150px]',
      mobileClassName: 'h-full w-[150px]',
    },
    {
      name: 'Courtside Ventures',
      src: '/images/landing-pages/about/backed-by/courtside.png',
      className: 'h-auto w-[150px]',
      mobileClassName: 'h-full w-[150px] !object-contain',
    },
    {
      name: 'Alumni Ventures',
      src: '/images/landing-pages/about/backed-by/alumni-ventures.png',
      className: 'h-auto w-[150px]',
      mobileClassName: 'h-full w-[150px]',
    },
    {
      name: 'BDMI',
      src: '/images/landing-pages/about/backed-by/bdmi.png',
      className: 'h-auto w-[150px]',
      mobileClassName: 'h-full w-[150px]',
    },
  ],
}

export const getServerSideProps = withSessionSsr(async (context: GetServerSidePropsContext) => {
  const { isLoggedIn } = context.req

  return {
    props: {
      isLoggedIn,
      leaderShipTeam: LEADERSHIP_TEAM,
      strategicAdvisor: OUR_STRATEGIC_ADVISORS,
      joinUs: JOIN_US,
      backedBy: BACKED_BY,
    },
  }
})

const Page = (props: AboutUsPageProps) => {
  const { leaderShipTeam, strategicAdvisor, joinUs, backedBy, isLoggedIn } = props

  return (
    <PublicLayout isTransparent={true}>
      <AboutUsPage
        isLoggedIn={isLoggedIn}
        leaderShipTeam={leaderShipTeam}
        strategicAdvisor={strategicAdvisor}
        joinUs={joinUs}
        backedBy={backedBy}
      />
    </PublicLayout>
  )
}

export default Page
